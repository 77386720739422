/**
 * Copyright 2020 EvolveSolutions.io, All Rights Reserved.
 */

require('dotenv').config()
var AWS = require('aws-sdk');

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_KEY_ID, 
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY, 
  region: process.env.REACT_APP_AWS_REGION
});

const fromEmailAddress = process.env.REACT_APP_FROM_EMAIL
const toEmailAddress = process.env.REACT_APP_TO_EMAIL

const sendEmail = (emailAddress) => {
  let params = createParams(emailAddress);
  var sendPromise = new AWS.SES({apiVersion: '2010-12-01'}).sendEmail(params).promise();
  sendPromise.then(
    function(data) {
        if(data.MessageId){
            console.log(`An email was sent with ID: ${data.MessageId}`)
        }
    }).catch(
      function(err) {
      console.error(err, err.stack);
  });
}

// Create sendEmail params 
const createParams = (inquirerEmail) => {
  var params = {
    Destination: { /* required */
      ToAddresses: [
        toEmailAddress
      ]
    },
    Message: { 
      Body: { 
        Html: {
         Charset: "UTF-8",
         Data: `Hi!, ${inquirerEmail} has visited the Evolve Solutions website and would like to get more info about Lattice Pro.`
        },
        Text: {
         Charset: "UTF-8",
         Data: "TEXT_FORMAT_BODY"
        }
       },
       Subject: {
        Charset: 'UTF-8',
        Data: 'Incoming request for info: Evolve Solutions'
       }
      },
    Source: fromEmailAddress, 
    ReplyToAddresses: [
      fromEmailAddress
    ],
  };
  return params;
}




export default sendEmail;