import React from 'react';

const ReferralSlide = (props) => {
  return (
    <div className="container-fluid bg-light space-top space-bottom">
      <div className="container referral-slide">
        <blockquote className="blockquote ">
            <div className="referral-quote">"{props.quote}"</div>
            <footer className="blockquote-footer">{props.companyName}<cite title="Source Title"> {props.name}</cite></footer>
        </blockquote>
      </div>
    </div>  
  )
}

const ReferralCarousel = () => {
  return (
      <div id="carouselExampleControls" className="carousel slide carousel-fade border-top border-bottom" data-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <ReferralSlide quote="Evolve had us up and running in no time. LatticePro has made compliance quick and easy." 
                           name="Greg D" />

          </div>
          <div className="carousel-item">
            <ReferralSlide quote="An indispensable tool in our workflow. Having the ability to dispatch, bill and report keeps us moving with ease." 
                           name="George M" />
          </div>
          <div className="carousel-item">
            <ReferralSlide quote="We Love it - Lattice Pro has allowed us to save time and money through its efficient workflow process giving me time to concentrate more on customer engagement and retention." 
                           name="Janice C" />    
          </div>
        </div>
        <a className="carousel-control-prev pr-5" href="#carouselExampleControls" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next pl-5" href="#carouselExampleControls" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>
      </div>

  )
}

export default ReferralCarousel;