import React from 'react';
import sendEmail from '../../services/ses-sendmail'
import $ from 'jquery'

const handleFormSubmit = async (e) => {
  e.preventDefault();
  let email = e.target.emailAddressInput.value;
  e.target.reset();
  $('#exampleModalCenter').modal('show')
  await sendEmail(email);
}

const TopLogo = () => {
  return (
    <>
    <div className="top-logo container-fluid space-top space-bottom bg-light px-4">
          <div className="row align-items-center h-100 d-flex justify-content-center">
            <div className="col-xs-3 col-sm-8 col-md-7 col-lg-4 col-xl-3 mx-5 mb-4">
              <img src="./lp_logo.png" className="img-fluid mb-xså-3" alt="Lattice Pro Logo"/>
              <h1 className="display-6 font-weight-normal mt-3">The Essential App</h1>
              <p className="lead font-weight-normal">LatticePro offers everything you need to ensure dependable-reliable operations with electronic Management Solutions.</p>
            </div>
            <div className="col-0 col-md-0 col-lg-1 col-xl-0"></div>
            <div className="col-xs-3 col-sm-8 col-md-8 col-lg-4 col-xl-3 mx-5">
              <form class="needs-validation" novalidate onSubmit={handleFormSubmit}>
                <div className="form-group mw-75">
                  <label htmlFor="emailAddressInput">Please enter your Email address and we will contact you.</label>
                  <input type="email" className="form-control" id="emailAddressInput" aria-describedby="emailHelp" placeholder="Enter email" required/>
                  <div class="invalid-feedback">
                    Invalid Email Address
                  </div>
                  <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                </div>
                <button type="submit" className="btn btn-primary" >Submit</button>
                {/* data-toggle="modal" data-target="#exampleModalCenter" */}
              </form>
              <div className="mt-5 text-secondary">
              <h3>Call us today for an online demonstration.</h3>
              <span className="telephone-link"><h2><a href="tel:952-356-5565 ">952-356-5565</a></h2></span>
              </div>
            </div>
            
          </div>
      </div>
      
      <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalCenterTitle">We recieved your email.</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              Thank you for requesting more info, we will contact you with more information.
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Ok</button>
            </div>
          </div>
        </div>
      </div>
      </>
  )
}

export default TopLogo;