import React from 'react'
import FeatherIcon from 'feather-icons-react'

const CardStack = () => {
    return (
        <div className="container-fluid space-top space-bottom bg-light">
          <div className="col-md-10 mx-auto">
            <ul className="list-unstyled">
              <li className="card mb-5 rounded shadow">
                <div className="card-body text-left my-4 mx-3">
                  <h2 className="mt-1 mb-4">Workflow <FeatherIcon icon="grid" color="rgb(111, 38, 61)"/></h2>
                  <p><FeatherIcon className="mx-2" icon="minus" color="rgb(111, 38, 61)"/> Use internal order intake, provide clients with access to place orders online and review orders.</p>
                  <p><FeatherIcon className="mx-2" icon="minus"  color="rgb(111, 38, 61)"/> Manage and track order distribution.</p>
                  <p><FeatherIcon className="mx-2" icon="minus"  color="rgb(111, 38, 61)"/> Automation tools to reduce documentation errors to fulfill clinical and billing compliance.</p>
                  <p><FeatherIcon className="mx-2" icon="minus"  color="rgb(111, 38, 61)"/> Robust easy to use search tools to search medical record anytime-anywhere to support client questions and answers</p>
                </div>
              </li>
              <li className="card mb-5 rounded shadow">
                <div className="card-body text-left my-4 mx-3">
                  <h2 className="mt-1 mb-4">Direct Billing <FeatherIcon icon="file-text" color="rgb(111, 38, 61)"/></h2>
                  <p><FeatherIcon className="mx-2" icon="minus" color="rgb(111, 38, 61)"/> Use internal order intake, provide clients with access to place orders online and review orders through the exchange portal.</p>
                  <p><FeatherIcon className="mx-2" icon="minus"  color="rgb(111, 38, 61)"/> Manage and track order distribution through the dispatch module.</p>
                  <p><FeatherIcon className="mx-2" icon="minus"  color="rgb(111, 38, 61)"/> Use internal order intake, provide clients with access to place orders online and review orders through the exchange portal.</p>
                </div>
              </li>
              <li className="card mb-5 rounded shadow">
                <div className="card-body text-left my-4 mx-3">
                  <h2 className="mt-1 mb-4">More <FeatherIcon icon="activity" color="rgb(111, 38, 61)"/></h2>
                  <p><FeatherIcon className="mx-2" icon="minus" color="rgb(111, 38, 61)"/>Cloud based solution. </p>
                  <p><FeatherIcon className="mx-2" icon="minus"  color="rgb(111, 38, 61)"/>Service support for multi state jurisdictions.</p>
                  <p><FeatherIcon className="mx-2" icon="minus"  color="rgb(111, 38, 61)"/>Integrations with Facility EMR, Physician EMR, Radiology group RIS, NPPES and more.</p>
                  <p><FeatherIcon className="mx-2" icon="minus"  color="rgb(111, 38, 61)"/>HL7 including ORM, ORU and DICOM.</p>
                  <p><FeatherIcon className="mx-2" icon="minus"  color="rgb(111, 38, 61)"/>Customizable Fee Schedules.</p>
                  <p><FeatherIcon className="mx-2" icon="minus"  color="rgb(111, 38, 61)"/>Support for electronic, secondary and paper claims. </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
    )
}

export default CardStack;