import React from 'react';

import Navigation from "./main/navigation";
import LandingPage from "./landing/landing";
import Footer from './main/footer';

const Index = () => {
  return (
    <div>
      <Navigation />  
      <LandingPage />
      <Footer />
    </div>
  )
}

export default Index;