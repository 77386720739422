import React from "react";
import TopLogo from './topLogo'
// import FeatureCards from './featureCards'
import CustomerReferrals from './customerReferrals'
import CardStack from './cardStack'

const VerticalCards = () => {
  return (
    <div className="dots-background container-fluid space-top space-bottom bg-light">
        <div className="row d-flex justify-content-center position-relative">
          <div className="card dots-card text-white bg-dark p-4 m-4">
            <div className="card-body">
              <h5 className="card-title">Billing Cycle</h5>
              <p className="card-text">Automated and integrated billing solution to reduce time and labor of invoicing and processing claims.</p>         
            </div>
          </div>
          <div className="card dots-card text-white bg-lp-dark p-4 m-4">
            <div className="card-body">
              <h5 className="card-title">Image Management</h5>
              <p className="card-text">Image routing, in application image viewing and secure long term image storage.</p> 
            </div>
          </div>
          <div className="card dots-card text-white bg-dark p-4 m-4">
            <div className="card-body">
              <h5 className="card-title">Regulatory Compliance</h5>
              <p className="card-text">Easily prepare and present documentation for compliance and surveys both State and Federal .</p>         
            </div>
          </div>
        </div>
      </div>
  )
}

const LandingPage = () => {
  return (
    <div className="LandingPage">
      <TopLogo />

      <div className="referral-background space-top space-bottom">
        <div className="col-md-9 p-lg-5 mx-auto">
          <div className="row align-items-center">
            <div className="col-6  text-center">
              {/* <p>Lattice Pro is built Portable X-Ray Provider for the Mobile X-Ray industry.</p> */}
            </div>
            <div className="referral-card col-6 text-left text-light align-middle p-5 shadow">
              <h5>"A complete Practice Management solution that will meet all your security and compliance requirements and enhance your operational and billing efficiencies."</h5>
            </div>
          </div>
        </div>
      </div>

      <VerticalCards />
      <CustomerReferrals />
      {/* <FeatureCards /> */}
      <CardStack />
        
        <div className="container-fluid space-top space-bottom bg-dark">
          <div className="text-light text-center">
            <p>LatticePro e Management Solutions will put your operations and billing in order across all departments. </p>
            <p>Let LatticePro prepare and optimize your ever changing organization needs and give you more time to work on your practice.</p>
            <h3>Call us today for an online demonstration.</h3>
            <h3>952-356-5565</h3>
          </div>
        </div>
    </div>
  )
}

export default LandingPage;