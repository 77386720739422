import React from 'react';
import ReferralCarousel from './referralCarousel'

const CustomerReferrals = () => {
    return (
        <>
            <ReferralCarousel />
        </>
    )
}

export default CustomerReferrals;