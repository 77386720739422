import React from 'react';

const Navigation = () => {
  return (
    <nav className="site-header sticky-top py-1">
      <div className="container d-flex flex-column flex-md-row justify-content-start">
        <a className="py-2 mr-3" href="/" aria-label="Product">
          <span className="font-weight-bolder">Evolve Solutions</span>
        </a>
        {/* <a className="py-2 px-4 d-none d-md-inline-block" href="/">Products</a> */}
        {/* <a className="py-2 px-4 d-none d-md-inline-block" href="/">About</a> */}
      </div>
    </nav>    
  )
}

export default Navigation;